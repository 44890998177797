import PropTypes from "prop-types";
import groupCountries from "../utils/group_countries";
import CheckboxView from "./checkbox_view";
import cx from "classnames";

const FilterCountries = ({ countries = [] }) => {
  if (countries.length === 0) return null;

  const groupedCountries = groupCountries(countries);
  return groupedCountries.map((gc, idx, arr) => (
    <div
      key={gc.name}
      className={cx("gdm-col gdm-col-24", {
        "gdm-m-bottom-md": idx !== arr.length - 1,
      })}
    >
      <CheckboxView
        label={gc.name}
        items={gc.countries}
        wrapperStyle={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
        }}
      />
    </div>
  ));
};

FilterCountries.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object),
};

export default FilterCountries;
