import PropTypes from "prop-types";
import { Dropdown } from "@capterra/arubaito";
import setSelectedMarket from "~/pages/PPL/actions/set_selected_market";
import setSelectedProduct from "~/pages/PPL/actions/set_selected_product";
import { useTranslation } from "~/utils";

const BidFiltersHeader = ({
  markets = [],
  selectedMarket = { marketId: 0 },
  products = [],
  selectedProduct = { productId: 0 },
  dispatch = () => {},
}) => {
  const { t } = useTranslation();

  const setMarket = (market) => {
    if (selectedMarket.marketId !== market.marketId) {
      dispatch(setSelectedMarket(market));
      dispatch(
        setSelectedProduct(
          products.find(
            (pr) => Number(pr.marketId) === Number(market.marketId),
          ),
        ),
      );
    }
  };

  const setProduct = (product) => {
    if (selectedProduct.productId !== product.productId) {
      dispatch(setSelectedProduct(product));
    }
  };

  return (
    <>
      <div className="gdm-m-top-md gdm-m-bottom-sm">
        <span className="gdm-m-right-xs">
          {markets.length > 0 && (
            <Dropdown
              list={markets}
              selected={selectedMarket}
              selectCallback={setMarket}
              render={(selected) => (
                <Dropdown.Selected>
                  {selected
                    ? selected.name
                    : t("PPL_BID-FILTERS_DROPDOWN_SELECT-MARKET")}
                </Dropdown.Selected>
              )}
              displayValue="name"
            >
              <Dropdown.List
                render={(item) => (
                  <Dropdown.ListItem key={item.marketId} item={item}>
                    {item.name}
                  </Dropdown.ListItem>
                )}
              />
            </Dropdown>
          )}
        </span>
        <span className="gdm-m-left-xs">
          {selectedMarket?.marketId && (
            <Dropdown
              selected={selectedProduct}
              selectCallback={setProduct}
              list={products.filter(
                (pr) => Number(pr.marketId) === Number(selectedMarket.marketId),
              )}
              render={(selected) => (
                <Dropdown.Selected>
                  {selected
                    ? selected.productLabel
                    : t("PPL_BID-FILTERS_DROPDOWN_SELECT-PRODUCT")}
                </Dropdown.Selected>
              )}
              displayValue="productLabel"
            >
              <Dropdown.List
                render={(item) => (
                  <Dropdown.ListItem key={item.productId} item={item}>
                    {item.productLabel}
                  </Dropdown.ListItem>
                )}
              />
            </Dropdown>
          )}
        </span>
      </div>
    </>
  );
};

BidFiltersHeader.propTypes = {
  markets: PropTypes.arrayOf(PropTypes.object),
  selectedMarket: PropTypes.shape({ marketId: PropTypes.string }),
  products: PropTypes.arrayOf(PropTypes.object),
  selectedProduct: PropTypes.shape({
    productId: PropTypes.string,
  }),
  dispatch: PropTypes.func,
};

export default BidFiltersHeader;
