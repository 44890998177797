import setSelectedProductFilters from "../set_selected_product_filters";

const fetchBidFilters = () => async (dispatch, getState) => {
  const {
    ppl: { selectedProduct },
  } = getState();

  if (!selectedProduct.productId) return;

  const response = await fetch(
    `/ppl/bids/filters/get?productId=${selectedProduct.productId}`,
  );
  const data = await response.json();

  if (data.error) throw new Error(response.statusText);

  data.industries.sort((indA, indB) => indA.name.localeCompare(indB.name));

  dispatch(setSelectedProductFilters(data));
};

export default fetchBidFilters;
