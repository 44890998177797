import PropTypes from "prop-types";
import { Inline, Text, Tooltip, Icon, Stack } from "~/components/vendorUI";
import { Label } from "~/components/Text";
import { css, cx } from "ui/css";

const checkboxCSS = css({
  accentColor: "{colors.steel}",
  opacity: "0.9",
  width: "14px",
  height: "14px",
  marginBlock: "8px",
});

const inlineCSS = css({
  breakInside: "avoid",
});

const stackCSS = css({
  marginInlineStart: "16px",
});

const CheckboxView = ({
  hideLabel = false,
  isChecked = false,
  label = "",
  labelHelperText = "",
  tooltipText = "",
  formControlLabelText = "",
  wrapperStyle,
  items = [],
}) => {
  const hasItems = items?.length > 0;

  const getLabel = () => {
    if (tooltipText) {
      return (
        <Inline gap="8px">
          <Label
            className={css({
              fontSize: "16px",
            })}
          >
            {label}
          </Label>
          <Tooltip content={tooltipText}>
            <Icon name="CircleInfo" color="sky" size="12px" />
          </Tooltip>
        </Inline>
      );
    }
    if (label)
      return (
        <div
          className={cx(
            "gdm-label",
            css({
              fontSize: "16px!",
            }),
          )}
        >
          {label}
        </div>
      );
    return null;
  };

  const getFormControlLabelText = () => {
    if (formControlLabelText) return formControlLabelText;
    if (label && labelHelperText) return `${label}: ${labelHelperText}`;
    return label;
  };

  const getItems = () => {
    return items.map(
      (item) =>
        Boolean(item.name) && (
          <Stack gap={0} key={item.id}>
            <Inline
              className={inlineCSS}
              key={`${item.id}:${item.name}`}
              align="center"
            >
              <input
                className={checkboxCSS}
                id={`${item.id}:${item.name}`}
                name="sizes"
                type="checkbox"
                checked={item.isActive}
                readOnly
              />
              <Text size="16px" color="secondary" asChild>
                <label htmlFor={`${item.id}:${item.name}`}>{item.name}</label>
              </Text>
            </Inline>
            {item.subSegment?.length > 0 && (
              <Stack className={stackCSS} gap={0}>
                {item.subSegment.map((sub) => (
                  <Inline
                    className={inlineCSS}
                    key={`${sub.id}:${sub.name}`}
                    align="center"
                  >
                    <input
                      className={checkboxCSS}
                      id={`${sub.id}:${sub.name}`}
                      name="sizes"
                      type="checkbox"
                      checked={sub.isActive}
                      readOnly
                    />
                    <Text size="16px" color="secondary" asChild>
                      <label htmlFor={`${sub.id}:${sub.name}`}>
                        {sub.name}
                      </label>
                    </Text>
                  </Inline>
                ))}
              </Stack>
            )}
          </Stack>
        ),
    );
  };

  return (
    <Stack gap="8px">
      {!hideLabel && getLabel()}
      <div style={wrapperStyle}>
        {hasItems ? (
          getItems()
        ) : (
          <Inline className={inlineCSS} align="center">
            <input
              className={checkboxCSS}
              id={getFormControlLabelText()}
              name="sizes"
              type="checkbox"
              checked={isChecked}
              readOnly
            />
            <Text size="16px" color="secondary" asChild>
              <label htmlFor={getFormControlLabelText()}>
                {getFormControlLabelText()}
              </label>
            </Text>
          </Inline>
        )}
      </div>
    </Stack>
  );
};

CheckboxView.propTypes = {
  hideLabel: PropTypes.bool,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  labelHelperText: PropTypes.string,
  tooltipText: PropTypes.string,
  formControlLabelText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default CheckboxView;
