import { lazy } from "react";
import { Switch, Redirect } from "react-router-dom";
import { useLocation } from "@remix-run/react";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import EditBids from "~/pages/PPL/pages/edit_bids";
import ChangedBids from "~/pages/PPL/pages/changed_bids";
import {
  ExpansionLeads,
  PrePuchaseLeadDetails,
  PrePuchaseLeadConfirm,
} from "~/pages/PPL/pages/expansion_leads";
import BidFilters from "~/pages/PPL/pages/bid_filters";
import { ProtectedRoute } from "~/components/ProtectedRoute";

export default function Routes() {
  const location = useLocation();
  const presenter = location?.state?.presenter;
  const { user } = useCurrentUser();
  const hasAccessTo = (permission) => user.permissions?.[permission]?.hasAccess;
  const shouldRedirectToLeads =
    !hasAccessTo("ppl_bidding") && hasAccessTo("ppl_leads");

  return (
    <>
      <Switch location={presenter || location}>
        {shouldRedirectToLeads ? (
          <Redirect from="/ppl/bids" to="/ppl/leads" exact />
        ) : null}

        <ProtectedRoute
          exact
          path={["/ppl/expansionleads", "/ppl/expansion-leads"]}
          hasAccess={hasAccessTo("ppl_bidding")}
        >
          {ExpansionLeads}
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={[
            "/ppl/expansionleads/:expansionLeadID",
            "/ppl/expansion-leads/:expansionLeadID",
          ]}
          hasAccess={hasAccessTo("ppl_bidding")}
        >
          {PrePuchaseLeadDetails}
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={[
            "/ppl/expansionleads/:expansionLeadID/purchase",
            "/ppl/expansion-leads/:expansionLeadID/purchase",
          ]}
          hasAccess={hasAccessTo("ppl_bidding")}
        >
          {PrePuchaseLeadConfirm}
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path="/ppl/bids/filters"
          hasAccess={hasAccessTo("ppl_bidding")}
        >
          {BidFilters}
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path="/ppl/bids/edit/:productId"
          hasAccess={hasAccessTo("ppl_bidding")}
        >
          {EditBids}
        </ProtectedRoute>

        <Redirect from="*" to="/404" />
      </Switch>
      <Switch>
        <ProtectedRoute
          exact
          path="/ppl/bids/edit/:productId"
          hasAccess={hasAccessTo("ppl_bidding")}
        >
          {ChangedBids}
        </ProtectedRoute>
      </Switch>
    </>
  );
}
