import { SET_MARKETS } from "~/pages/PPL/reducers/markets_reducer";
import { SET_SELECTED_MARKET } from "~/pages/PPL/reducers/selected_market_reducer";
import { SET_SELECTED_PRODUCT } from "~/pages/PPL/reducers/selected_product_reducer";
import axios from "axios";

export const getMarkets = () =>
  axios.get("/ppl/api/resources").then((response) => ({
    ok: response.status >= 200 && response.status < 300,
    json: () => Promise.resolve(response.data),
    data: response.data,
    status: response.status,
  }));

const fetchMarkets = () => async (dispatch, getState) => {
  const {
    ppl: { products },
  } = getState();

  const response = await getMarkets();

  if (!response.ok) {
    dispatch({
      type: SET_MARKETS,
      payload: [],
    });
    throw new Error(response.statusText);
  }

  const { markets = [], selectedMarket = null } = response.data;

  dispatch({
    type: SET_MARKETS,
    payload: markets.map((market) => ({
      name: market.name,
      marketId: market.id,
    })),
  });

  dispatch({
    type: SET_SELECTED_MARKET,
    payload: { name: selectedMarket.name, marketId: selectedMarket.id },
  });

  const activeProduct = products.find(
    (p) => Number(p.marketId) === Number(selectedMarket.id),
  );

  dispatch({
    type: SET_SELECTED_PRODUCT,
    payload: activeProduct,
  });

  return null;
};

export default fetchMarkets;
