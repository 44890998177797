import axios from "axios";
import setProducts from "./set_products";

const productsSort = (p1, p2) =>
  p1.productLabel.toLowerCase() < p2.productLabel.toLowerCase() ? -1 : 1;

export const getProducts = () =>
  axios.get("/ppl/api/resources").then((response) => ({
    ok: response.status >= 200 && response.status < 300,
    json: () => Promise.resolve(response.data),
    data: response.data,
    status: response.status,
  }));

const fetchProducts = () => async (dispatch) => {
  const response = await getProducts();

  if (!response.ok) {
    dispatch(setProducts([]));
    throw new Error(response.status === 404 ? 404 : response.statusText);
  }

  let productList = response.data?.products || [];
  productList = productList.map((product) => ({
    ...product,
    productId: product.localProductId,
    productName: product.globalName,
    productLabel: product.localProductName,
  }));
  productList.sort(productsSort);

  dispatch(setProducts(productList));

  return null;
};

export default fetchProducts;
